import { c as createCommonjsModule, g as getDefaultExportFromCjs } from '../common/_commonjsHelpers-8c19dec8.js';
import { h as helpers, u as utils } from '../common/index-67e564a0.js';

var lib = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Plugin = function () {
    function Plugin() {
        _classCallCheck(this, Plugin);

        this.isSwupPlugin = true;
    }

    _createClass(Plugin, [{
        key: "mount",
        value: function mount() {
            // this is mount method rewritten by class extending
            // and is executed when swup is enabled with plugin
        }
    }, {
        key: "unmount",
        value: function unmount() {
            // this is unmount method rewritten by class extending
            // and is executed when swup with plugin is disabled
        }
    }, {
        key: "_beforeMount",
        value: function _beforeMount() {
            // here for any future hidden auto init
        }
    }, {
        key: "_afterUnmount",
        value: function _afterUnmount() {}
        // here for any future hidden auto-cleanup


        // this is here so we can tell if plugin was created by extending this class

    }]);

    return Plugin;
}();

exports.default = Plugin;
});

var DOCUMENT_NODE_TYPE = 9;

/**
 * A polyfill for Element.matches()
 */
if (typeof Element !== 'undefined' && !Element.prototype.matches) {
    var proto = Element.prototype;

    proto.matches = proto.matchesSelector ||
                    proto.mozMatchesSelector ||
                    proto.msMatchesSelector ||
                    proto.oMatchesSelector ||
                    proto.webkitMatchesSelector;
}

/**
 * Finds the closest parent that matches a selector.
 *
 * @param {Element} element
 * @param {String} selector
 * @return {Function}
 */
function closest (element, selector) {
    while (element && element.nodeType !== DOCUMENT_NODE_TYPE) {
        if (typeof element.matches === 'function' &&
            element.matches(selector)) {
          return element;
        }
        element = element.parentNode;
    }
}

var closest_1 = closest;

/**
 * Delegates event to a selector.
 *
 * @param {Element} element
 * @param {String} selector
 * @param {String} type
 * @param {Function} callback
 * @param {Boolean} useCapture
 * @return {Object}
 */
function _delegate(element, selector, type, callback, useCapture) {
    var listenerFn = listener.apply(this, arguments);

    element.addEventListener(type, listenerFn, useCapture);

    return {
        destroy: function() {
            element.removeEventListener(type, listenerFn, useCapture);
        }
    }
}

/**
 * Delegates event to a selector.
 *
 * @param {Element|String|Array} [elements]
 * @param {String} selector
 * @param {String} type
 * @param {Function} callback
 * @param {Boolean} useCapture
 * @return {Object}
 */
function delegate(elements, selector, type, callback, useCapture) {
    // Handle the regular Element usage
    if (typeof elements.addEventListener === 'function') {
        return _delegate.apply(null, arguments);
    }

    // Handle Element-less usage, it defaults to global delegation
    if (typeof type === 'function') {
        // Use `document` as the first parameter, then apply arguments
        // This is a short way to .unshift `arguments` without running into deoptimizations
        return _delegate.bind(null, document).apply(null, arguments);
    }

    // Handle Selector-based usage
    if (typeof elements === 'string') {
        elements = document.querySelectorAll(elements);
    }

    // Handle Array-like based usage
    return Array.prototype.map.call(elements, function (element) {
        return _delegate(element, selector, type, callback, useCapture);
    });
}

/**
 * Finds closest match and invokes callback.
 *
 * @param {Element} element
 * @param {String} selector
 * @param {String} type
 * @param {Function} callback
 * @return {Function}
 */
function listener(element, selector, type, callback) {
    return function(e) {
        e.delegateTarget = closest_1(e.target, selector);

        if (e.delegateTarget) {
            callback.call(element, e);
        }
    }
}

var delegate_1 = delegate;

var lib$1 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();



var _plugin2 = _interopRequireDefault(lib);



var _delegate2 = _interopRequireDefault(delegate_1);





function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PreloadPlugin = function (_Plugin) {
    _inherits(PreloadPlugin, _Plugin);

    function PreloadPlugin() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, PreloadPlugin);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PreloadPlugin.__proto__ || Object.getPrototypeOf(PreloadPlugin)).call.apply(_ref, [this].concat(args))), _this), _this.name = "PreloadPlugin", _this.onContentReplaced = function () {
            _this.swup.preloadPages();
        }, _this.onMouseover = function (event) {
            var swup = _this.swup;

            swup.triggerEvent('hoverLink', event);

            var link = new helpers.Link(event.delegateTarget);
            if (link.getAddress() !== (0, helpers.getCurrentUrl)() && !swup.cache.exists(link.getAddress()) && swup.preloadPromise == null) {
                swup.preloadPromise = swup.preloadPage(link.getAddress());
                swup.preloadPromise.route = link.getAddress();
                swup.preloadPromise.finally(function () {
                    swup.preloadPromise = null;
                });
            }
        }, _this.preloadPage = function (pathname) {
            var swup = _this.swup;

            var link = new helpers.Link(pathname);
            return new Promise(function (resolve, reject) {
                if (link.getAddress() != (0, helpers.getCurrentUrl)() && !swup.cache.exists(link.getAddress())) {
                    (0, helpers.fetch)({ url: link.getAddress(), headers: swup.options.requestHeaders }, function (response) {
                        if (response.status === 500) {
                            swup.triggerEvent('serverError');
                            reject();
                        } else {
                            // get json data
                            var page = swup.getPageData(response);
                            if (page != null) {
                                page.url = link.getAddress();
                                swup.cache.cacheUrl(page, swup.options.debugMode);
                                swup.triggerEvent('pagePreloaded');
                            } else {
                                reject(link.getAddress());
                                return;
                            }
                            resolve(swup.cache.getPage(link.getAddress()));
                        }
                    });
                } else {
                    resolve(swup.cache.getPage(link.getAddress()));
                }
            });
        }, _this.preloadPages = function () {
            (0, utils.queryAll)('[data-swup-preload]').forEach(function (element) {
                _this.swup.preloadPage(element.href);
            });
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(PreloadPlugin, [{
        key: 'mount',
        value: function mount() {
            var swup = this.swup;

            swup._handlers.pagePreloaded = [];
            swup._handlers.hoverLink = [];

            swup.preloadPage = this.preloadPage;
            swup.preloadPages = this.preloadPages;

            // register mouseover handler
            swup.delegatedListeners.mouseover = (0, _delegate2.default)(document.body, swup.options.linkSelector, 'mouseover', this.onMouseover.bind(this));

            // initial preload of page form links with [data-swup-preload]
            swup.preloadPages();

            // do the same on every content replace
            swup.on('contentReplaced', this.onContentReplaced);
        }
    }, {
        key: 'unmount',
        value: function unmount() {
            var swup = this.swup;

            swup._handlers.pagePreloaded = null;
            swup._handlers.hoverLink = null;

            swup.preloadPage = null;
            swup.preloadPages = null;

            swup.delegatedListeners.mouseover.destroy();

            swup.off('contentReplaced', this.onContentReplaced);
        }
    }]);

    return PreloadPlugin;
}(_plugin2.default);

exports.default = PreloadPlugin;
});

var __pika_web_default_export_for_treeshaking__ = /*@__PURE__*/getDefaultExportFromCjs(lib$1);

export default __pika_web_default_export_for_treeshaking__;

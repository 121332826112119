import Swup from "../_snowpack/pkg/swup.js";
import SwupPreloadPlugin from "../_snowpack/pkg/@swup/preload-plugin.js";

// first animate in
setTimeout(function () {
  document.documentElement.classList.remove("is-animating");
}, 100);

const swup = new Swup({
  plugins: [new SwupPreloadPlugin()],
});
